
const titles = document.querySelectorAll('.faq .title');
const states = { true: 'remove', false: 'add' };

const handleClick = ({ currentTarget: { parentElement } }) => {
  const isOpen = parentElement.classList.contains('open');
  titles.forEach((title) => title.parentElement.classList.remove('open'));
  parentElement.classList[states[isOpen]]('open');
};

titles.forEach((title) => title.onclick = handleClick);